<template>
  <ShadowCard
    :param="{
      name: 'Balances',
      showButtons: true,
    }"
    :isLoading="isLoading">
    <EditableTable
      :fields="fields"
      :storeData="storeData"
      :storePath="storePath"
      :id_property="id_property"
      :item_name="'Balance'"
      :initialSort="[{ field: 'value_date', type: 'desc' }]"
      :usesPortfolioSelector="true"/>
  </ShadowCard>
</template>

<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import EditableTable from "./EditableTable.vue";

export default {
  name: "balances",
  components: { EditableTable, ShadowCard },
  data() {
    return {
      isLoading: true,
      items: [],
      storeData: "BALANCES",
      storePath: "balances",
      id_property: "id",

      fields: [
        {
          type: "text",
          field: "year",
          label: "Year",
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          validation: "required|date:YYYY|min:2000,value",
          needToBeInteger: true,
          filterOptions: {
            enabled: true,
            placeholder: "Year",
            trigger: "keyup",
          },
        },
        {
          type: "date",
          field: "value_date",
          label: "Date",
          tdClass: "text-center",
          thClass: "text-center",
          editable: true,
          formatFn: this.dateFormatFn,
          validation: "required",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "yyyy-MM-dd",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Date", // placeholder for filter input
            trigger: "keyup", //only trigger on enter not on keyup
          },
        },
        {
          type: "select",
          field: "portfolio_id",
          label: "Portfolio ID",
          options: this.portfolioList,
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          validation: "required",
          filterOptions: {
            enabled: true,
            placeholder: "Portfolio ID",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          field: "currency",
          label: "Ccy",
          options: this.currenciesList,
          tdClass: "text-center",
          thClass: "text-center",
          editable: true,
          sumColumn: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Ccy", // placeholder for filter input
            trigger: "keyup", //only trigger on enter not on keyup
          },
        },
        {
          type: "number",
          field: "amount",
          label: "Amount",
          prefix: "$",
          precision: 2,
          thClass: "text-center",
          tdClass: "text-right",
          editable: true,
          summable: true,
          needToBeInteger: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Amount", // placeholder for filter input
            trigger: "keyup", //only trigger on enter not on keyup
          },
        },

        {
          actions: "actions",
          field: "Actions",
          label: "Actions",
          thClass: "text-center",
          tdClass: "text-center",
          html: true,
        },
      ],
    };
  },
  watch: {
    portfolioList: {
      immediate: true,
      handler(newVal) {
        this.updateSelectOptions("portfolio_id", newVal);
      },
    },
    currenciesList: {
      immediate: true,
      handler(newVal) {
        this.updateSelectOptions("currency", newVal);
      },
    },
  },
  mounted() {
    this.$store.commit(`SET_TABLE_EDIT_MODE`, "false");
    this.setEditableFields();
  },
  methods: {
    updateSelectOptions(fieldName, options) {
      const fieldToUpdate = this.fields.find(
        (field) => field.field === fieldName
      );
      if (fieldToUpdate) {
        fieldToUpdate.options = options;
      }
    },
    dateFormatFn(value) {
      try {
        let formattedDate = new Date(value).toISOString().split("T")[0];

        return formattedDate;
      } catch (error) {
        return value.substring(0, 10);
      }
    },
    setEditableFields() {
      this.$store.commit("SET_TABLE_EDITABLEFIELDS", this.editableFields);
    },
  },
  computed: {
    editableFields() {
      let editableFields = this.fields.filter((field) => field.editable);

      return editableFields;
    },
    portfolioList() {
      return this.$store.state.portfolioList.map((portfolio) => ({
        value: portfolio.portfolio_id,
        label: portfolio.portfolio_id,
      }));
    },

    currenciesList() {
      return this.$store.state.currenciesList.map((currency) => ({
        value: currency.currency_code,
        label: currency.currency_code + " - " + currency.description,
      }));
    },
  },
};
</script>
